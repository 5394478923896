import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/chat/batten")({
  component: PlantDoctorPage,
});

function PlantDoctorPage() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.octocom.ai/chatbot/bundle.js";
    script.id = "octocom-script";
    script.setAttribute(
      "data-config-id",
      "dfcc3bb7-2e8c-4030-b907-4d4507c47157",
    );
    script.setAttribute("data-open-window", "true");
    script.setAttribute("referrerpolicy", "no-referrer-when-downgrade");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      id="octocom-container"
      className="sm:absolute sm:left-1/2 sm:top-16 sm:z-10 sm:h-[80vh] sm:max-h-[900px] sm:w-[400px] sm:-translate-x-1/2 2xl:w-[500px]"
    ></div>
  );
}
